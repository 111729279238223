<template>
  <div>
    <custom-dialog
      :visible="$route.params.form == 'AddEditForm'"
      :editMode="editMode"
      title="Order Guide"
      :closeDisabled="closeDisabled"
      :awaitingResponse="awaitingResponse"
      @close="$emit('close')"
      @submit="submitHandle"
    >
      <v-row justify="start" class="mt-2">
        <v-col cols="12">
          <v-text-field
            v-model="newOrderGuide.name"
            class="mr-1"
            label="Order Guide Name"
            outlined
          />
        </v-col>
      </v-row>
      <v-row justify="start" class="mt-0">
        <v-col cols="6">
          <v-select
            v-model="newOrderGuide.supplierId"
            :items="suppliers"
            item-text="name"
            item-value="id"
            outlined
            label="Supplier"
          />
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="newOrderGuide.restaurantId"
            :items="restaurants"
            item-text="name"
            item-value="id"
            outlined
            label="Owned Restaurant (Optional)"
          />
        </v-col>
      </v-row>
    </custom-dialog>
  </div>
</template>

<script>
import CustomDialog from "@/AuthenticatedContent/shared/dialogs/FormDialog.vue"
import { mapState } from "vuex"

export default {
  name: "add-edit-form",
  components: {
    CustomDialog,
  },
  data() {
    return {
      newOrderGuide: {
        name: "",
        restaurantId: "",
        supplierId: "",
      },
      closeDisabled: false,
      awaitingResponse: false,
    }
  },
  computed: {
    ...mapState(["restaurants", "suppliers", "itemToEdit"]),
    editMode() {
      return this.itemToEdit != null
    },
  },
  watch: {
    "$route.params.form": {
      handler(newValue) {
        if (newValue == "AddEditForm") {
          if (this.editMode) {
            this.prepEdit()
          }
        } else {
          this.$emit("close")
          this.clearFields()
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    async submitHandle() {
      this.closeDisabled = this.awaitingResponse = true
      await this.$store
        .dispatch("updateOrderGuides", this.newOrderGuide)
        .then(() => {
          this.closeDisabled = this.awaitingResponse = false
          if (this.$route.params.form != null) {
            this.$router.push({ params: { form: null, id: null } })
          }
        })
      this.$emit("close")
    },
    clearFields() {
      this.newOrderGuide = {}
    },
    prepEdit() {
      Object.assign(this.newOrderGuide, this.itemToEdit)
    },
  },
}
</script>
