<template>
  <div>
    <form-dialog
      :visible="$route.params.form == 'AddEditForm'"
      :editMode="editMode"
      title="Supplier Item"
      :closeDisabled="closeDisabled"
      :submitDisabled="$v.$anyError"
      :awaitingResponse="awaitingResponse"
      :error="mainErrorMessage"
      @close="$emit('close')"
      @submit="submitHandle()"
    >
      <fieldset class="pa-3 pt-2">
        <legend class="px-1">Basic information:</legend>
        <v-row class="mt-0 mb-2">
          <v-col cols="3">
            <v-text-field
              v-model="brand"
              label="Brand"
              dense
              outlined
              hide-details="auto"
              :error-messages="getFormErrors($v, 'brand')"
            />
          </v-col>
          <v-col cols="9">
            <v-text-field
              v-model="name"
              label="Item Name"
              dense
              outlined
              hide-details="auto"
              :error-messages="getFormErrors($v, 'name')"
            />
          </v-col>
          <v-col cols="3">
            <v-select
              v-model="supplierId"
              label="Supplier"
              :items="suppliers"
              item-text="name"
              item-value="id"
              outlined
              dense
              hide-details="auto"
              :error-messages="getFormErrors($v, 'supplierId')"
            />
          </v-col>
          <v-col cols="5">
            <v-text-field
              v-model="supplierSKU"
              label="Supplier Product ID"
              dense
              outlined
              hide-details="auto"
              :error-messages="getFormErrors($v, 'supplierSKU')"
            />
          </v-col>
          <v-col cols="4">
            <v-select
              v-model="orderGuideId"
              label="Order Guide"
              :items="filteredOrderGuides"
              item-value="id"
              item-text="name"
              outlined
              dense
              hide-details="auto"
              :error-messages="getFormErrors($v, 'orderGuideId')"
            />
          </v-col>
        </v-row>
      </fieldset>
      <fieldset class="pa-3 mt-4">
        <legend class="px-2">Cost & quantity:</legend>
        <v-row>
          <v-col cols="2" style="position: relative">
            <v-text-field
              v-model="unitCost"
              label="Amount"
              dense
              outlined
              prefix="$"
              hide-details="auto"
              class="mr-3"
              :error-messages="getFormErrors($v, 'unitCost')"
              @keyup="calculateCaseCost()"
            />
            <span class="between-columns">per</span>
          </v-col>
          <v-col cols="2">
            <v-select
              v-model="supplierUnit"
              :items="units"
              item-text="name"
              item-value="id"
              dense
              outlined
              label="Unit"
              :menu-props="{ bottom: true, offsetY: true }"
              hide-details="auto"
              :error-messages="getFormErrors($v, 'supplierUnit')"
            />
          </v-col>
          <v-col cols="3">
            <measurement-field
              v-model="packageMeasurement"
              :selectedUnitId="supplierUnit"
              :allowUnitSelection="false"
              label="Package Size"
            />
          </v-col>
          <v-col cols="3" style="position: relative">
            <v-text-field
              v-model="caseSize"
              :label="`Case size` + (!caseSize ? ` (in packages)` : ``)"
              :suffix="caseSize ? `packages` : ``"
              dense
              outlined
              hide-details="auto"
              :error-messages="getFormErrors($v, 'caseSize')"
            />
            <span class="between-columns">=</span>
          </v-col>
          <v-col cols="2">
            <v-text-field
              v-model="caseCost"
              label="Cost per case"
              prefix="$"
              dense
              outlined
              hide-details="auto"
              :error-messages="getFormErrors($v, 'caseSize')"
              @keyup="calculateUnitCost()"
            />
          </v-col>
        </v-row>
      </fieldset>
      <fieldset class="pa-3 pt-3 mt-4">
        <legend class="px-1">Volumetric conversion:</legend>
        <v-row>
          <v-col cols="3">
            <v-text-field
              v-model="gramsPerCup"
              dense
              outlined
              hide-details="auto"
              :error-messages="getFormErrors($v, 'gramsPerCup')"
              suffix="grams per cup"
            />
          </v-col>
          <v-col cols="1" class="ml-n4 align-self-center">
            <ai-button
              v-if="baseIsGrams"
              :prompt="`How many cups is 1 gram of ${name}? Provide answer as a number without any units.`"
              :max-tokens="20"
              @answer="gramsPerCup = 1 / $event"
            />
            <ai-button
              v-else
              :prompt="`How many grams is 1 cup of ${name}? Provide answer as a number without any units.`"
              :max-tokens="20"
              @answer="gramsPerCup = $event"
            />
          </v-col>
          <v-col v-if="unitCost && gramsPerCup" cols="4">
            <!-- show cost per cup and per kilogram -->
            <v-text-field
              dense
              outlined
              hide-details="auto"
              :value="costPerCup.toFixed(2)"
              prefix="$"
              suffix="per cup"
              disabled
            />
          </v-col>
          <v-col v-if="unitCost && gramsPerCup" cols="4">
            <v-text-field
              dense
              outlined
              hide-details="auto"
              :value="costPerKilogram.toFixed(2)"
              prefix="$"
              suffix="per kilogram"
              disabled
            />
          </v-col>
        </v-row>
      </fieldset>
      <fieldset class="pa-3 pt-2 mt-4">
        <legend class="px-1">Linked inventory item:</legend>
        <v-row>
          <v-col cols="7">
            <v-radio-group
              v-model="linkedItemType"
              row
              class="mt-0"
              dense
              hide-details="auto"
            >
              <v-radio label="Unlinked" value=""></v-radio>
              <v-radio
                label="Link to existing inventory item"
                value="existing"
              ></v-radio>
              <!-- currently we disabled this option as it needs more work -->
              <v-radio
                v-if="false"
                label="Create and link to new inventory item"
                value="new"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col v-if="linkedItemType === 'existing'" cols="5">
            <v-autocomplete
              v-model="linkedItemID"
              class="my-0"
              label="Inventory Item"
              :items="items"
              :menu-props="{ bottom: true, offsetY: true }"
              item-text="name"
              item-value="itemID"
              outlined
              dense
              hide-details="auto"
              :error-messages="getFormErrors($v, 'linkedItemID')"
            />
          </v-col>
        </v-row>
        <v-row v-if="linkedItemType === 'new'">
          <v-col cols="3">
            <v-text-field
              v-model="newInvItem.itemID"
              label="Item ID"
              outlined
              hide-details="auto"
              :error-messages="getFormErrors($v, 'newInvItem.itemID')"
            />
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="newInvItem.name"
              label="Name"
              outlined
              hide-details="auto"
              :error-messages="getFormErrors($v, 'newInvItem.name')"
            />
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="newInvItem.threshold"
              label="Restock At"
              outlined
              hide-details="auto"
              :error-messages="getFormErrors($v, 'newInvItem.threshold')"
            />
          </v-col>
        </v-row>
        <v-row v-if="linkedItemType === 'new'">
          <v-col cols="4">
            <v-select
              v-model="newInvItem.category"
              :items="categories"
              outlined
              label="Category"
              hide-details="auto"
              :error-messages="getFormErrors($v, 'newInvItem.category')"
            />
          </v-col>
          <v-col cols="4">
            <v-select
              v-model="newInvItem.subcategory"
              :items="subcategories"
              outlined
              label="Subcategory"
              hide-details="auto"
              :error-messages="getFormErrors($v, 'newInvItem.subcategory')"
            />
          </v-col>
          <v-col cols="4">
            <v-select
              v-model="newInvItem.storage"
              :items="storages"
              item-text="name"
              item-value="id"
              outlined
              label="Storage"
              hide-details="auto"
              :error-messages="getFormErrors($v, 'newInvItem.storage')"
            />
          </v-col>
        </v-row>
      </fieldset>
    </form-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex"
import FormDialog from "@/AuthenticatedContent/shared/dialogs/FormDialog.vue"
import AiButton from "@/AuthenticatedContent/shared/AiButton.vue"
import { required, decimal } from "vuelidate/lib/validators"
import topLevelMixins from "@/AuthenticatedContent/mixins.js"
import MeasurementField from "@/AuthenticatedContent/shared/forms/MeasurementField.vue"

export default {
  name: "add-edit-form",
  components: { FormDialog, AiButton, MeasurementField },
  mixins: [topLevelMixins],
  validations() {
    return {
      supplierSKU: { required },
      name: { required },
      orderGuideId: { required },
      supplierId: { required },
      supplierUnit: { required },
      caseSize: { required, decimal },
      unitCost: { required, decimal },
    }
  },
  data() {
    return {
      supplierUnit: null,
      supplierSKU: null,
      orderGuideId: null,
      brand: null,
      name: null,
      supplierId: null,
      unitCost: null,
      caseSize: 1,
      caseCost: null,
      gramsPerCup: null,
      linkedItemType: "",
      linkedItemID: null,
      newInvItem: {},
      supplierItemID: null,
      awaitingResponse: false,
      closeDisabled: false,
      mainErrorMessage: "",
      packageMeasurement: this.createEmptyMeasurementObject(),
    }
  },
  computed: {
    ...mapState([
      "firebaseRefs",
      "categories",
      "subcategories",
      "storages",
      "units",
      "suppliers",
      "orderGuides",
      "supplierItems",
      "items",
    ]),
    filteredOrderGuides() {
      return this.orderGuides.filter(
        orderGuide => orderGuide.supplierId == this.supplierId
      )
    },
    supplierUnitName() {
      const unit =
        this.supplierUnit && this.units.find(unit => unit.id == this.supplierUnit)
      return unit ? unit.name : "unit"
    },
    baseIsGrams() {
      return this.units.find(unit => unit.id == this.supplierUnit)?.base == "gram"
    },
    costPerKilogram() {
      if (!this.unitCost || !this.supplierUnit) return 0

      const valInBase =
        this.units.find(unit => unit.id == this.supplierUnit)?.valInBase || 1
      const baseCost = this.unitCost / valInBase
      if (this.baseIsGrams) {
        return baseCost * 1000
      } else {
        const costPerCup = baseCost * 236.6
        return (costPerCup / this.gramsPerCup) * 1000
      }
    },
    costPerCup() {
      if (!this.unitCost || !this.supplierUnit) return 0

      const valInBase =
        this.units.find(unit => unit.id == this.supplierUnit)?.valInBase || 1
      const baseCost = this.unitCost / valInBase
      if (this.baseIsGrams) {
        return baseCost * this.gramsPerCup
      } else {
        return baseCost * 236.6
      }
    },
    packageSize() {
      if (!this.packageMeasurement?.quantity) return 0
      const packageUnitConversion = this.packageMeasurement.preferredDisplayUnitId
        ? this.units.find(
            unit => unit.id == this.packageMeasurement.preferredDisplayUnitId
          ).valInBase
        : 1
      return this.packageMeasurement.quantity / packageUnitConversion
    },
    editMode() {
      return this.itemToEdit != null
    },
    itemToEdit() {
      return this.supplierItems.find(
        supplierItem => supplierItem.id == this.$route.params.id
      )
    },
  },
  watch: {
    "$route.params.form": {
      handler(newValue) {
        if (newValue !== "AddEditForm") {
          this.$emit("close")
        }
      },
      deep: true,
      immediate: true,
    },
    "$route.params.id": {
      handler(newValue, oldValue) {
        if (newValue != oldValue && newValue) {
          this.prepEdit()
        }
      },
      deep: true,
      immediate: true,
    },
    supplierUnit: function () {
      this.packageMeasurement = {
        quantity: this.packageMeasurement.quantity,
        unit: this.units.find(unit => unit.id == this.supplierUnit)?.base,
        preferredDisplayUnitId: this.supplierUnit,
      }
      this.calculateCaseCost()
    },
    "packageMeasurement.quantity": function () {
      this.calculateCaseCost()
    },
    caseSize() {
      this.calculateCaseCost()
    },
  },
  created() {
    if (this.orderGuides.length === 1) {
      this.orderGuideId = this.orderGuides[0].id
    }
    if (this.suppliers.length === 1) {
      this.supplierId = this.suppliers[0].id
    }
  },
  methods: {
    ...mapActions(["fetchSupplierItems", "updateSupplierItems"]),
    async submitHandle() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.closeDisabled = this.awaitingResponse = true

        try {
          // if (this.linkedItemType === "new") {
          //   await setDoc(
          //     doc(this.firebaseRefs.itemsRef, this.newInvItem.itemID),
          //     this.newInvItem
          //   ).catch(error => {
          //     this.mainErrorMessage = "Error saving item: " + error.message
          //   })
          // }
          await this.updateSupplierItems({
            id: this.supplierItemID,
            supplierSKU: this.supplierSKU,
            name: this.name,
            brand: this.brand,
            linkedItemID:
              this.linkedItemType !== ""
                ? this.linkedItemID || this.newInvItem?.itemID || null
                : null,
            supplierId: this.supplierId,
            caseSize: this.caseSize,
            packageMeasurement: this.packageMeasurement,
            costMoney: this.getCostMoneyFromUnitCost(
              this.unitCost,
              this.supplierUnit
            ),
            orderGuideId: this.orderGuideId,
            gramsPerCup: this.gramsPerCup,
          })
            .then(() => {
              this.$router.push({ params: { form: null, id: null } })
              this.clearFields()
              this.$emit("close")
            })
            .catch(error => {
              console.error(error)
              this.mainErrorMessage = "Error saving item: " + error.message
            })
          this.awaitingResponse = this.closeDisabled = false
        } catch (error) {
          this.mainErrorMessage = "Error saving item: " + error.message
          this.awaitingResponse = this.closeDisabled = false
        }
      }
    },
    calculateCaseCost() {
      if (this.unitCost && this.packageMeasurement?.quantity && this.caseSize) {
        this.caseCost = (this.unitCost * this.packageSize * this.caseSize).toFixed(2)
      }
    },
    calculateUnitCost() {
      if (this.caseCost && this.packageMeasurement?.quantity && this.caseSize) {
        this.unitCost = (this.caseCost / this.packageSize / this.caseSize).toFixed(8)
      }
    },
    prepEdit() {
      if (!this.itemToEdit) {
        return
      }
      this.supplierItemID = this.itemToEdit.id
      this.supplierSKU = this.itemToEdit.supplierSKU
      this.name = this.itemToEdit.name
      this.brand = this.itemToEdit.brand
      this.orderGuideId = this.itemToEdit.orderGuideId || null
      this.gramsPerCup = this.itemToEdit.gramsPerCup
      this.supplierId = this.itemToEdit.supplierId
      this.unitCost = this.getUnitCostFromCostMoney(
        this.itemToEdit.costMoney,
        this.itemToEdit.packageMeasurement.preferredDisplayUnitId
      )
      this.supplierUnit = this.itemToEdit.packageMeasurement.preferredDisplayUnitId
      this.caseSize = this.itemToEdit.caseSize
      this.packageMeasurement = this.itemToEdit.packageMeasurement
      if (this.itemToEdit.linkedItemID != null) {
        this.linkedItemType = "existing"
        this.linkedItemID = this.itemToEdit.linkedItemID
      } else {
        this.newInvItem = {}
        this.linkedItemType = ""
        this.linkedItemID = null
      }
      this.calculateCaseCost()
    },
    clearFields() {
      this.supplierItemID =
        this.supplierSKU =
        this.name =
        this.brand =
        this.orderGuideId =
        this.gramsPerCup =
        this.supplierId =
        this.unitCost =
        this.supplierUnit =
        this.caseSize =
          null
      this.packageMeasurement = this.createEmptyMeasurementObject()
      this.newInvItem = {}
      this.linkedItemType = ""
      this.mainErrorMessage = ""
    },
  },
}
</script>

<style scoped>
.between-columns {
  position: absolute;
  right: -5px;
  top: 22px;
}
</style>
