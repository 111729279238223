<template>
  <div>
    <page-component
      pageTitle="Order Guides"
      :headers="headers"
      :items="orderGuides"
      :buttons="[
        { text: '+ Add New Order Guide', to: { params: { form: 'AddEditForm' } } },
      ]"
    >
      <template v-slot:[`item.restaurantIds`]="props">
        {{ restaurantName(props.item.restaurantIds) || "Default" }}
      </template>
      <template v-slot:[`item.supplier`]="props">
        {{ getSupplierName(props.item.supplierId) }}
      </template>
      <template v-slot:[`item.items`]="props">
        {{ getNumberOfSupplierItems(props.item.id) }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <nxg-action-btn type="edit" @click="editOrderGuide(item)" />
        <nxg-action-btn type="delete" item-type="ORDER_GUIDE" :item="item" />
      </template>
    </page-component>
    <add-edit-form
      @close="$router.push({ params: { form: null, id: null }, query: {} })"
    />
  </div>
</template>

<script>
import AddEditForm from "./AddEditForm.vue"
import { mapState, mapGetters } from "vuex"
import PageComponent from "@/AuthenticatedContent/shared/PageComponent.vue"

export default {
  name: "order-guides",
  components: {
    AddEditForm,
    PageComponent,
  },
  data() {
    return {
      headers: [
        { text: "Name", value: "name", class: "header-style" },
        { text: "Supplier", value: "supplier", class: "header-style" },
        { text: "Payer", value: "restaurantIds", class: "header-style" },
        { text: "Items", value: "items", class: "header-style" },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          class: "header-style",
        },
      ],
    }
  },
  computed: {
    ...mapGetters(["getRestaurantName", "getSupplierName"]),
    ...mapState(["orderGuides", "supplierItems"]),
  },
  methods: {
    restaurantName(restaurantIds) {
      var nameArray = []
      restaurantIds?.forEach(id => {
        nameArray.push(this.getRestaurantName(id))
      })
      return nameArray.join(", ")
    },
    getNumberOfSupplierItems(orderGuideId) {
      return this.supplierItems.filter(item => item.orderGuideId === orderGuideId)
        .length
    },
    async editOrderGuide(orderGuide) {
      await this.$store.commit("setItemToEdit", orderGuide)
      this.$router.push({ params: { form: "AddEditForm", id: orderGuide.id } })
    },
  },
}
</script>

<style></style>
