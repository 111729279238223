<template>
  <v-btn fab color="primary" x-small :disabled="loading" @click="getAnswer">
    <v-progress-circular
      v-if="loading"
      indeterminate
      size="28"
    ></v-progress-circular>
    <v-icon v-else>mdi-robot</v-icon>
  </v-btn>
</template>

<script>
import topLevelMixins from "../mixins.js"

export default {
  name: "ai-button",
  mixins: [topLevelMixins],
  props: {
    prompt: {
      type: String,
      required: true,
    },
    maxTokens: {
      type: Number,
      required: false,
      default: 64,
    },
    temperature: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    async getAnswer() {
      this.loading = true
      const res = await this.getOpenAiAnswer(this.prompt)
      this.$emit("answer", res)
      this.loading = false
    },
  },
}
</script>
