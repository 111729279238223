<template>
  <div>
    <page-component
      pageTitle="Supplier Items"
      :headers="headers"
      :items="filteredSupplierItems"
      :loadingData="loadingData"
      no-data-text="No supplier items for the given filters"
      :buttons="[
        { text: '+ Add New Supplier Item', to: { params: { form: 'AddEditForm' } } },
      ]"
    >
      <template v-slot:buttons>
        <v-menu v-if="false" offset-y style="z-index: 200">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="secondary" dark v-bind="attrs" v-on="on">Bulk Add</v-btn>
          </template>
          <v-list>
            <v-list-item disabled>
              <v-list-item-title>Import file (excel or csv)</v-list-item-title>
            </v-list-item>
            <v-list-item disabled>
              <v-list-item-title>Import from invoice</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:filters[0]>
        <custom-select
          v-model="selectedSupplierIds"
          :items="suppliers"
          item-value="id"
          item-text="name"
          label="Supplier"
          multiple
          dense
        />
      </template>
      <template v-slot:filters[1]>
        <custom-select
          v-model="selectedOrderGuideIds"
          :items="filteredOrderGuides"
          item-value="id"
          item-text="name"
          label="Order Guide"
          multiple
          dense
        />
      </template>
      <template v-slot:[`item.cost`]="{ item }">
        <span v-if="item.packageMeasurement">
          ${{
            Math.round(
              item.caseSize *
                getCostPerUnit(item) *
                getQuantityInBaseUnits(item.packageMeasurement.quantity, item.id) *
                100
            ) / 100
          }}
          / case
        </span>
      </template>
      <template v-slot:[`item.packageSize`]="{ item }">
        <span v-if="item.packageMeasurement">
          {{ getQuantityInBaseUnits(item.packageMeasurement.quantity, item.id) }}
          {{ getUnitName(item.packageMeasurement.preferredDisplayUnitId) }}
        </span>
        <span v-else>-</span>
      </template>
      <template v-slot:[`item.caseSize`]="{ item }">
        <span v-if="item.caseSize">
          {{ item.caseSize }} package{{ item.caseSize > 1 ? "s" : "" }}
        </span>
      </template>
      <template v-slot:[`item.supplierId`]="{ item }">
        {{ getSupplierName(item.supplierId) }}
      </template>
      <template v-slot:[`item.orderGuide`]="{ item }">
        {{ getOrderGuideName(item.orderGuideId) }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <nxg-action-btn type="edit" @click="editItem(item)" />
        <nxg-action-btn type="delete" item-type="SUPPLIER_ITEM" :item="item" />
      </template>
    </page-component>
    <add-edit-form
      v-if="this.$route.params.form === 'AddEditForm'"
      @close="$router.push({ params: { form: null, id: null }, query: {} })"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex"
import AddEditForm from "./AddEditForm"
import PageComponent from "@/AuthenticatedContent/shared/PageComponent.vue"
import CustomSelect from "@/AuthenticatedContent/shared/CustomSelect.vue"
import topLevelMixins from "@/AuthenticatedContent/mixins.js"

export default {
  name: "supplier-items",
  components: { PageComponent, AddEditForm, CustomSelect },
  mixins: [topLevelMixins],
  data() {
    return {
      selectedOrderGuideIds: [],
      selectedSupplierIds: [],
      loadingData: false,
    }
  },
  computed: {
    ...mapState(["suppliers", "orderGuides", "supplierItems"]),
    ...mapGetters(["getUnitName", "getSupplierName", "getOrderGuideName"]),
    headers() {
      const headers = [
        { text: "Supplier Item ID", value: "supplierSKU" },
        { text: "Name", value: "name" },
        { text: "Item ID", value: "linkedItemID" },
        { text: "Cost", value: "cost" },
        { text: "Package Size", value: "packageSize" },
        { text: "Case Size", value: "caseSize" },
        { text: "Supplier", value: "supplierId" },
        { text: "Order Guide", value: "orderGuide" },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "center",
        },
      ]
      if (this.suppliers.length < 2) {
        headers.splice(5, 1)
      }
      if (this.orderGuides.length < 2) {
        headers.splice(5, 1)
      }
      return headers
    },
    filteredSupplierItems() {
      return this.supplierItems.filter(
        item =>
          (!this.selectedSupplierIds.length ||
            this.selectedSupplierIds.includes(item.supplierId)) &&
          (!this.selectedOrderGuideIds.length ||
            this.selectedOrderGuideIds.includes(item.orderGuideId))
      )
    },
    filteredOrderGuides() {
      let filteredOrderGuides = this.orderGuides
      if (this.selectedSupplierIds) {
        filteredOrderGuides = filteredOrderGuides.filter(orderGuide =>
          this.selectedSupplierIds.includes(orderGuide.supplierId)
        )
      }
      return filteredOrderGuides
    },
  },
  created: async function () {
    this.loadingData = true
    this.selectedSupplierIds = this.suppliers.map(supplier => supplier.id)
    this.selectedOrderGuideIds = this.orderGuides.map(orderGuide => orderGuide.id)
    this.loadingData = false
  },
  methods: {
    async editItem(item) {
      await this.$store.commit("setItemToEdit", item)
      this.$router.push({ params: { form: "AddEditForm", id: item.id } })
    },
  },
}
</script>
